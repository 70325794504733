<template>
    <div class="d-flex align-items-center">
        <UserProfile class="log-use-profile" :data="data.userData" :show-dot="false" :width="'30px'" :thumbnail="'30x30'" />
        <div class="ml-015 wrapperNameImage">
            <span v-html="convert(data.Message)"></span>
            <span>&nbsp;{{getDateAndTime(data.createdAt == undefined ? new Date().getTime(): new Date(data?.createdAt).getTime())}}</span>
        </div>
    </div>
</template>
<script setup>
import UserProfile from "@/components/atom/UserProfile/UserProfile.vue";
import { defineComponent,defineProps } from "vue";
import { useProjects } from '@/composable/projects';
import moment from "moment";

const {getDateAndTime} = useProjects();

defineComponent({
    name: "ActivityContent",
    components: {
        UserProfile
    },
})
defineProps({
    data: Object,
});
function convert(message) {
    const regex = /TIMESTAMP_[0-9]+/g;
    let updatedMessage = message;

    const matches = message.match(regex);
    if (matches) {
        for (const match of matches) {
            const timestamp = match.replace("TIMESTAMP_", "");
            const formattedTime = moment(parseInt(timestamp)).format("hh:mm A");
            updatedMessage = updatedMessage.replace(match, formattedTime);
        }
    }

    return updatedMessage;
}
</script>
<style src="./style.css"></style>