import { createRouter, createWebHashHistory } from 'vue-router'
import auth from './auth'
import projects from './projects'
import chat from './chat'
import settings from './settings'
import timesheet from './timesheet'
import milestonesheet from './milestonesheet'
import payment from './payment';

import { useCustomComposable } from '@/composable'
import chargebeeRouter from "../plugins/chargebee/router";
import paddleRouter from "../plugins/paddle/router";
import { apiRequestWithoutCompnay } from '@/services'
import * as env from '@/config/env';
import Cookies from 'js-cookie'
const checkoutRoute = process.env.VUE_APP_PAYMENTMETHOD === "chargebee" ? chargebeeRouter?.checkoutRoute : process.env.VUE_APP_PAYMENTMETHOD === "paddle" ? paddleRouter?.checkoutRoute : [];


const routes = [
	...auth,

	// PROJECT ROUTES
	...projects,

	// PROJECT ROUTES
	...chat,

	// SETTINGS ROUTES
	...settings,
	
	// TIMESHEET ROUTES
	...timesheet,

	//MILESTONE ROUTES
	...milestonesheet,

	// PAYMENTS ROUTES
	...payment,
	{
		path: "/",
		name: "dashboard",
		component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
		meta: {
			title: 'Home',
			requiresAuth: true,
		}
	},
	{
		path: "/:cid",
		name: "Home",
		component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
		meta: {
			title: 'Home',
			requiresAuth: true,
		}
	}, {
		path: "/:catchAll(.*)",
		name: "404",
		component: () => import(/* webpackChunkName: "404" */ '@/views/NotFound'),
		meta: {
			title: '404'
		}
	},
	...checkoutRoute,
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})

// const authInst = getAuth();
const jsonData = require('../../../brandSettings.json');
const {setTitle} = useCustomComposable()
router.beforeEach(async(to, _, next) => {
	const localUserId = localStorage.getItem("userId");
	const app = localUserId ? await apiRequestWithoutCompnay('get',`${env.USER_UPATE}/${localUserId}`) : null;
	let user = app && app?.status === 200 ? app?.data || null : null;
	
	// onAuthStateChanged(authInst, (user) => {
		// CHECK META FOR AUTH REQUIRED
		// const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
		const requiresAuth = to.meta.requiresAuth;
		const token = Cookies.get('accessToken') || '';
		// SET PAGE TITLE
		setTitle({title: to.meta.title, prefix: jsonData?.productName ? `${jsonData.productName} | ` : ''});

		if(user === null && requiresAuth === true) {
			// IF USER IS NOT LOGGED IN AND REQUESTS AUTH REQUIRED PAGE
			next({name: 'Log-in', query: {redirect_url: to.fullPath}});
			return;
		} else if(user !== null && requiresAuth === false) {
			// IF USER IS LOGGED IN AND REQUESTS NO AUTH REQUIRED PAGE
			if(to.meta.title === 'Support'){
				next();
			}else if(token && !user?.AssignCompany?.length){
				next({name: "Create_Company"});
			}else{
				next({name: "Home", params: {
					cid: localStorage.getItem('selectedCompany') ? localStorage.getItem('selectedCompany') : '',
				}});
			}
			return;
		} else {
			next();
			return;
		}
	// })
})

export default router;
